/* App.css */

.App {
  display: grid;
  grid-template-areas: 
    "header header header"
    "nav content content"
    "footer footer footer";
  grid-template-columns: 250px 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
 
  }
  
  .header {
    grid-area: header;
    background-color: #f8f8f8; /* Vaalea, neutraali tausta */
    padding: 10px;
    text-align: left;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #333;
    
  }
 
    .footer {
    grid-area: footer;
    background-color: #f8f8f8; /* Vaalea, neutraali tausta */
    padding: 10px;
    text-align: lest;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #333;
  }
  
  .navigation-bar {
    grid-area: nav;
    background-color: #eaeaea; /* Hillitty harmaa tausta */
    background-image: url('./assets/background2.jpg');
    background-position:right -80px center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    width: 200px;
    border: 1px solid #cccccc; /* Kevyt kehys */
    box-shadow: none; /* Ei varjostusta */
  }
  
  .nav-button {
    margin-bottom: 10px; /* Vähemmän tilaa nappuloiden välillä */
    width: 100%; /* Täyttää navigaatiopalkin leveyden */
    background-color: #f5f5f5; /* Vaalea taustaväri */
    border: none; /* Ei kehystä */
    padding: 10px 15px;
    text-align: left; /* Teksti vasemmalle */
    font-size: 1em;
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #333; /* Tumma tekstiväri */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .nav-link {
    color: #4e4848;
    text-decoration: none;
  
  }
  
  .nav-button:hover {
    background-color: #dddddd; /* Tummempi tausta hover-tilassa */
  }
 /* Content-osion tyylit */
.content  {
 
 
    margin: 20px;
    margin-left: 50px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-width: 1500px;
    padding-left: 30px;
  }
  .text-section, .home-section {
  
    background-color: #ffffff;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
    max-width: 1700px;
    color: #333;
   
    
  }
  .home-text-section {
    overflow-y: auto; /* Varmistaa, että ylittävä sisältö on vieritettävissä */
    max-height: 800px; /* Säädä tämä korkeus tarpeidesi mukaan */
    color: #666;
   
  }
  .create-text-section {
    overflow-y: auto; /* Varmistaa, että ylittävä sisältö on vieritettävissä */
    max-height: 800px; /* Säädä tämä korkeus tarpeidesi mukaan */
    color: #666;
    width: auto;
    font-size: 0.8em;
   
  
  }
  
 
  /* Kirjoittajan profiili -osio */
.profile-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd; /* Viiva tekstin alapuolella */
  }
  
  .profile-image {
    width: 50px; /* Säädä kuvan kokoa */
    height: 50px;
    border-radius: 50%; /* Pyöreä kuva */
    margin-right: 15px;
  }
  
  .profile-text {
    font-size: 1em;
    color: #333;
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-between; /* Aseta napit sivujen vastakkaisiin reunoille */
    margin: 20px 0; /* Lisää marginaalia ylä- ja alapuolelle */
  }
  
  .nav-page-button {
    padding: 10px 20px; /* Pienennä paddingia */
    border: none; /* Poista kehys */
    background-color: transparent; /* Läpinäkyvä tausta */
    color: #333; /* Tumma tekstiväri */
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 1em; /* Normaali fonttikoko */
    cursor: pointer; /* Muuta kursori kädensijaksi napin päällä */
    text-decoration: underline; /* Alleviivaa teksti */
  }
  
  .nav-page-button:hover {
    color: #b2dfdb; /* Muuta väriä kun hiiri on napin päällä */
    text-decoration: none; /* Poista alleviivaus hover-tilassa */
  }


  .modal-pictures {
    
    margin-left: 80px;
    margin-right: 15px;
    transition: transform 0.3s ease; /* Lisää siirtymä tehoste suurennuksen yhteydessä */
  }
  
  .modal-pictures:hover {
    transform: scale(1.5); /* Suurennetaan kuvaa hover-tilassa */
  }
  /* Johdanto-osion tyylit */


  .intro-section p {
    font-size: 1.2em; /* Sopiva fonttikoko luettavuudelle */
    line-height: 1.6; /* Riviväli parantamaan luettavuutta */
    margin-bottom: 2em; /* Tilaa kappaleiden välillä */
    text-align: left;
  
  }


  p {
    line-height: 1.6;
    margin-bottom: 1em;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 18px;
  }
  
   h2 {
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-family: 'Helvetica', 'Arial', sans-serif;

    
  }

  h1 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: #666; /* Keskitumma tekstiväri */ 
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 1.8em;
  }
   .card {
    width: 350px; /* Voit säätää kortin leveyttä tarpeidesi mukaan */
    height: 370px; /* Kortin korkeus mukautuu sisällön mukaan */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333; /* Tumma tekstiväri */
    margin: 20px;
    overflow: hidden;
    position: relative;
    text-align: center;
    
  }
  
  .card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; /* Tämä kattaa koko kortin */
    background-color: rgba(255, 255, 255, 0.8); /* Vaalean verhon väri ja läpinäkyvyys */

    pointer-events: none; /* Sallii klikkaustapahtumien läpimenon */
  }

  .card-content {
    position: relative; /* Tämä on avainasemassa */
    padding: 20px;
    text-align: left; /* Aseta tekstin vaakasuora kohdistus vasemmalle */
   
  }
  
 .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    
  }

  .idea-icon {
    width: 20px;
    height: 20px;
  }

  .create-info {
    background-color: #8caaa6; 
    /* background-color: #a9bbba;  */
    border-radius: 20px;
    padding: 20px;
    color:#ffffff;
    font-size: 1em;
    font-family: 'Helvetica', 'Arial', sans-serif;

  }

  .rules-container {
    margin: 30px auto;
    max-width: 1200px;
    background-color: #c7c3c3; /* A light background to distinguish the section */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
    color: #333;
    text-align: center;
    margin-bottom: 20px; /* Space below the title */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Tai käytä 'flex-start'
    cursor: pointer; /* Muuta kursori kädensijaksi napin päällä */
  }
  .rule {
    width: calc(25% - 20px); /* Esimerkki neljälle kortille riviä kohden */
    margin: 10px; /* Säädä marginaalia tarpeen mukaan */
    /* Lisää tarvittavia tyylejä */
}
  .modal-content {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }


